import { useState } from 'react'
import { motion } from 'framer-motion'

import { useMobile } from '../../hooks/useMobile'

import './Graphic.scss'
import { MdOutlineExpandMore } from 'react-icons/md'
import { BiDownArrowAlt } from 'react-icons/bi'
import { IconContext } from 'react-icons'

const Graphic = ({ isGraphic, setIsGraphic }) => {

  const isMobile = useMobile(window)

  return (
    <motion.div 
      className={`graphic`}
      animate={{ top: isGraphic ? '0%' : '-120%' }}
      transition={{ duration: 1, type: 'spring', bounce: 0.25  }}
    >
      <motion.div
              onClick={() => {setIsGraphic(false)}}
              className='back'
              animate={{
                y: [0, -20, 0, 0, 0]
              }}
              transition={{ duration: 5, repeat: Infinity }}
          >
            <MdOutlineExpandMore size={isMobile ? 20 : 28} fill='black' className='arrow-button' />
            <p className="back-text">Back to main page</p>
          </motion.div>
      <h1 className='graphic-title'>Fancy <span className='gradient-text'>graphic design</span><br/> section coming soon.</h1>
    </motion.div>
  )
}

export default Graphic