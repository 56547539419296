import React from 'react'
import { motion } from 'framer-motion'
import { FiExternalLink } from 'react-icons/fi'
import { BiGitRepoForked } from 'react-icons/bi'
import { AiOutlineStar } from 'react-icons/ai'

import './Project.scss'

const Project = ({ name, description, date, stars, forks, url }) => {
  return (
    <motion.div 
      whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
      whileHover={{ scale: [1, 1.05]}}
      transition={{ duration: 0.4 }}
      className='project'
    >
      <div className='project-content'>
        <p className='date'>{date}</p>
        <a className='name' href={url} target='_blank' rel='noreferrer'>{name}</a>
        <p className='text'>{description ? (description.length > 100 ? description.substring(0,100) + '...' : description) : 'No description.'}</p>
      </div>
      <div className='icons-and-numbers'>
        <div className='top-icons'>
          <motion.div 
            className='redirect-icon' 
            whileHover={{ opacity: 1, cursor: 'pointer' }}
            transition={{ duration: 0.2 }}
          >
            <a href={url} target='_blank' rel='noreferrer'><FiExternalLink /></a>
            
          </motion.div>
        </div>
        <div className='bottom-icons'>
          <div className='star'>
            <div className='star-icon'>
              <AiOutlineStar />
            </div>
            <p className='count star-count'>{stars}</p>
          </div>
        </div>
        
        
      </div>
    </motion.div>
  )
}

export default Project