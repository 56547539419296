import React from 'react'
import { motion } from 'framer-motion'

import './Square.scss'

const Square = ({Icon, skillName}) => {
  return (
    <motion.div 
      whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
      whileHover={{ scale: [1, 1.1, 1.1]}}
      transition={{ duration: 0.5 }}
      className='square'
    >
      <div className='icon-area'>
        <Icon className='icon'/>
      </div>
      <h4>{skillName}</h4>
    </motion.div>
  )
}

export default Square