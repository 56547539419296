import React from 'react'
import { motion } from 'framer-motion'
import FeaturedItem from '../FeaturedItem/FeaturedItem'
import './Featured.scss'
import video from './../../assets/screen-record.mp4'

const featuredData = [
  {
    title: 'Vantacore',
    type: 'AI-Powered Tech Insights App',
    desc: "An AI-powered tool that scrapes the world's top tech review sites to bring you precise, reliable insights.",
    image: 'vantacore.png',
    imageType: 'image',
    workInProgress: true,
    alt: 'vantacore',
    link: 'https://vantacore.vercel.app/',
    github: '',
    tools: ['Next 14', 'OpenAI', 'PostgreSQL', 'Prisma', 'GCP', 'Tailwind']
  },
  {
    title: 'LingoMoose',
    type: 'Language Learning App w/Spotify',
    desc: "An innovative app that combines language learning with music using Spotify, offering a fun and engaging way to pick up a new language.",
    image: 'lingomoose.png',
    imageType: 'image',
    workInProgress: true,
    alt: 'lingomoose',
    link: '',
    github: '',
    tools: ['Next 14', 'PostgreSQL', 'Prisma', 'Spotify API', 'Tailwind']
  },
  {
    title: 'JetKampüs',
    type: 'Real World EdTech Project',
    desc: 'An EdTech app designed for students in Turkey, featuring summaries, flashcards, exercises, progress tracker to support and enhance their learning experience.',
    image: 'jetkampus.png',
    imageType: 'image',
    workInProgress: true,
    alt: 'gamehub',
    link: 'https://jetkampus.com',
    github: '',
    tools: ['Next 13', 'React', 'Firebase', 'Tailwind', 'Lexical']
  },
  {
    title: 'Gamehub',
    type: 'Fullstack Streaming App',
    desc: 'Twitch-like live streaming application in Next 14.',
    image: 'gamehub.png',
    imageType: 'image',
    workInProgress: false,
    alt: 'gamehub',
    link: 'https://gamehub-usinakenes.vercel.app/',
    github: 'https://github.com/usinakenes/gamehub',
    tools: ['Next 14', 'React', 'PostgreSQL', 'Prisma', 'Clerk', 'Tailwind']
  },
  {
    title: 'Docuchat',
    type: 'T3 Stack, SaaS',
    desc: 'An application to chat with your PDF files. (Database sleeps occasionally, contact me to wake it up.)',
    image: 'docuchat.png',
    imageType: 'image',
    workInProgress: false,
    alt: 'league-stats',
    link: 'https://docuchat-iota.vercel.app',
    github: 'https://github.com/usinakenes/docuchat',
    tools: ['Next 13', 'React', 'MySQL', 'Prisma', 'Stripe', 'Kinde', 'Tailwind']
  },
  {
    title: 'Code to Image',
    type: 'Frontend',
    desc: 'A tiny web application to turn your code snippet into a beautiful-looking image for you to present.',
    image: 'code-to-img.png',
    imageType: 'image',
    workInProgress: false,
    alt: 'code-to-img',
    link: 'https://https://code-to-img-steel.vercel.app/',
    github: 'https://github.com/usinakenes/code-to-img',
    tools: ['Next 13', 'React', 'Tailwind']
  },
  // {
  //   title: 'League Stats',
  //   type: 'Frontend, Proxy Server',
  //   desc: 'A simple application to fetch latest match details of a player in League of Legends.',
  //   image: 'league.png',
  //   imageType: 'image',
  //   alt: 'league-stats',
  //   link: 'https://lol-stats.netlify.app/',
  //   github: 'https://github.com/usinakenes/league-stats',
  //   tools: ['React', 'Express', 'Riot API', 'Sass']
  // },
  {
    title: 'Biljetta Ticket System',
    type: 'Full Stack (PERN)',
    desc: 'A responsive ticket system application designed and built by a group of eight for a school project.',
    image: 'ticket-system.png',
    alt: 'ticket-system',
    imageType: 'image',
    workInProgress: false,
    link: '',
    github: 'https://github.com/usinakenes/ticket-system',
    tools: ['React', 'Docker', 'Redux', 'PostgreSQL', 'Prisma', 'Tailwind']
  },

]

const Featured = ({ featuredRef}) => {
  return (
    <div ref={featuredRef} className='featured'>
      <motion.h1 
        className='featured-title' 
        whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 0.7 }}
      >FEATURED PROJECTS</motion.h1>
      <div className='featured-container'>
        {
          featuredData.map((item) => (
            <FeaturedItem key={item.title} item={item} />
          ))
        }
      </div>
    </div>
  )
}

export default Featured

