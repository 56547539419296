import React from 'react'
import { motion } from 'framer-motion'
import { MdOutlineLightbulb } from 'react-icons/md'
import { SlBubbles } from 'react-icons/sl'
import { TbMath } from 'react-icons/tb'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { HiTrendingUp } from 'react-icons/hi'



import './Skills.scss'

const skills = [
  {
    title: 'Creative and Innovative',
    desc: 'I possess a remarkable ability to think outside the box and come up with creative and innovative solutions to problems.',
    icon: <MdOutlineLightbulb />
  },
  {
    title: 'Problem Solver',
    desc: 'I enjoy analyzing challenges from various angles and using my critical thinking skills to find effective and efficient solutions.' ,
    icon: <MdOutlineLightbulb />
  },
  {
    title: 'Trendy',
    desc: 'I place great emphasis on staying up-to-date by using modern technologies and following the latest trends.',
    icon: <HiTrendingUp />
  },
  {
    title: 'Good Communicator',
    desc: "I am skilled in communicating effectively by actively listening, expressing my thoughts clearly, and considering others' perspectives with empathy. " ,
    icon: <MdOutlineLightbulb />
  }

]


const Skills = ({ skillsRef }) => {
  return (
    <div ref={skillsRef} className='skills'>
      <motion.h1 
        className='skills-title' 
        whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 0.4 }}
      >SKILLS</motion.h1>
      <div className='skills-container'>
          <motion.div 
            className='skill-text'
            whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
            whileHover={{ scale: [1, 1.05]}}
            transition={{ duration: 0.4 }}
          >
            <MdOutlineLightbulb className='icon'/>
            <h4>{skills[0].title}</h4>
            <p>{skills[0].desc}</p>
          </motion.div>
          <motion.div 
            className='skill-text'
            whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
            whileHover={{ scale: [1, 1.05]}}
            transition={{ duration: 0.4 }}
          >
            <IoMdCheckmarkCircleOutline className='icon'/>
            <h4>{skills[1].title}</h4>
            <p>{skills[1].desc}</p>
          </motion.div>
          <motion.div
            className='skill-text'
            whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
            whileHover={{ scale: [1, 1.05]}}
            transition={{ duration: 0.4 }}
          >
            <HiTrendingUp className='icon'/>
            <h4>{skills[2].title}</h4>
            <p>{skills[2].desc}</p>
          </motion.div>
          <motion.div 
            className='skill-text'
            whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
            whileHover={{ scale: [1, 1.05]}}
            transition={{ duration: 0.4 }}
          >
            <SlBubbles className='icon'/>
            <h4>{skills[3].title}</h4>
            <p>{skills[3].desc}</p>
          </motion.div>
      </div>
    </div>
  )
}

export default Skills