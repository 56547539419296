import React, { useState } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { BsGithub } from 'react-icons/bs'
import { motion } from 'framer-motion'
import './FeaturedItem.scss'
import { useMobile } from '../../hooks/useMobile'
import { FaToolbox, FaTools } from 'react-icons/fa'

const FeaturedItem = ({ item }) => {

  const [hovered, setHovered] = useState(false)

  const isMobile = useMobile(window, 1000)
  const isMobileForTools = useMobile(window, 600)


  return (
    <motion.div 
      className={`featured-item-card`} 
      whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
      whileHover={{ scale: [1, 1.02]}}
      transition={{ duration: 0.3 }}
      onMouseOver={() => setHovered(true)} 
      onMouseOut={() => setHovered(false)}
    >
        {item.workInProgress && (
          <div className='work-in-progress'><FaTools size={10} style={{ fill: '#222', marginRight: 2 }} /> Work in Progress</div>
        )}
        {item.imageType === 'image' ? 
          <img className={`featured-image ${hovered ? 'hovered' : ''}`} src={item.image} alt={item.alt}></img>
            :
          <video loop autoPlay playsInline muted className={item.title === 'Game of Life' && 'game-of-life'} src={item.image} alt={item.alt} type='video/mp4'></video>
        }
        <div className='featured-item-content'>
            <h5 className='' >{item.type}</h5>
            {(item.link || item.github) ? (
              <a className='title' href={item.link || item.github || '/'} target='noreferrer'><h4>{item.title}</h4></a>
            ) : (
              <h4 style={{ cursor: 'default' }}>{item.title}</h4>
            )}
            
            <p>{item.desc ? (item.desc.length > (isMobile ? 80 : 180) ? item.desc.substring(0,(isMobile ? 80 : 180)) + '...' : item.desc) : 'No description.'}</p>
            <div className={`tools-container`}>
              <motion.div className={`tools ${(isMobileForTools && item.title === 'Biljetta Ticket System') && 'long-move'} ${(isMobileForTools && (item.title === 'Sina Real Estate' || item.title === '3D Portfolio Website' )) && 'mid-move'} ${(isMobileForTools && item.title === 'League Stats') && 'short-move'}`}>
                {item.tools.map((tool) => (
                  <div key={tool} className='tool'>{tool}</div>
                ))}
              </motion.div>
            </div>
        </div>
        <div className='links'>
          {item.link && 
            <a href={item.link} target='noreferrer'>
              <motion.div whileHover={{ scale: [1, 1.15]}} transition={{ duration: 0.2 }}>
                <FiExternalLink className='redirect-icon icon'/>
              </motion.div>
            </a>
          }
          {item.github && 
            <a href={item.github} target='noreferrer'>
              <motion.div whileHover={{ scale: [1, 1.15]}} transition={{ duration: 0.2 }}>
                <BsGithub className='github-icon icon'/>
              </motion.div>
            </a>
          }
        </div>
    </motion.div>
  )
}

export default FeaturedItem