import React, { useState, useEffect, useRef } from 'react';

import './App.scss';
import About from './components/About/About'
import Content from './components/Content/Content';
import Graphic from './components/Graphic/Graphic';

function App() {

  const skillsRef = useRef(null)
  const squaresRef = useRef(null)
  const projectsRef = useRef(null)
  const contactRef = useRef(null)
  const featuredRef = useRef(null)

  const refs = [skillsRef, squaresRef, projectsRef, contactRef, featuredRef]

  const [isLightMode, setIsLightMode] = useState(false)
  const [isGraphic, setIsGraphic] = useState(false)

  useEffect(() => {
    if(isLightMode){
      
    }
  }, [isLightMode])
  

  return (
    <div className="App">
      <About refs={refs} isGraphic={isGraphic} setIsGraphic={setIsGraphic} />
      <Content refs={refs} />
      <Graphic isGraphic={isGraphic} setIsGraphic={setIsGraphic} />
    </div>
  );
}

export default App;
