import React, { useCallback, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'

import Squares from '../Squares/Squares'
import Projects from '../Projects/Projects'
import Contact from '../Contact/Contact'
import './Content.scss'
import Skills from '../Skills/Skills'
import Featured from '../Featured/Featured'
import { useMobile } from '../../hooks/useMobile'

const Content = ({ refs }) => {

  const contentRef = useRef(null)
  const [skillsRef, squaresRef, projectsRef, contactRef, featuredRef] = refs
  const isMobile = useMobile(window)
  const [hasAlreadyScrolled, setHasAlreadyScrolled] = useState(false)

  const handleScroll = useCallback(() => {
    setHasAlreadyScrolled(true)
  }, [])

  useEffect(() => {
    const div = contentRef.current
    div.addEventListener("scroll", handleScroll)
  }, [handleScroll])

  console.log(hasAlreadyScrolled)

  return (
    <motion.div
      className='content'
      ref={contentRef}
      animate={ isMobile ? {} : (hasAlreadyScrolled ? {} : {
        y: [0, 50, 0]
      })}
      transition={ isMobile ? {} : { duration: 1, delay: 5 }}
    >
        <Skills skillsRef={skillsRef} />
        <Squares squaresRef={squaresRef} />
        <Featured featuredRef={featuredRef} />
        <Projects projectsRef={projectsRef} />
        {/* <Contact contactRef={contactRef} /> */}
    
    </motion.div>
  )
}

export default Content