import React from 'react'
import { DiSass } from 'react-icons/di'
import { SiThreedotjs, SiExpress, SiRedux, SiReact, SiMongodb, SiPostgresql, SiPrisma, SiTailwindcss, SiMaterialui, SiTypescript, SiNextdotjs, SiMysql, SiFirebase } from 'react-icons/si'
import { FaNodeJs } from 'react-icons/fa'
import { motion } from 'framer-motion'



import Square from '../Square/Square'
import './Squares.scss'

const Squares = ({ squaresRef }) => {

  

  return (
    <div ref={squaresRef} className='squares'>
      <motion.h1 
        className='squares-title' 
        whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 0.7 }}
      >TECHNOLOGIES</motion.h1>
      <div className='squares-container'>
        <Square Icon={SiNextdotjs} skillName='Next 13+'/>
        <Square Icon={SiReact} skillName='React'/>
        <Square Icon={SiTypescript} skillName='Typescript'/>
        <Square Icon={SiTailwindcss} skillName='Tailwind'/>
        <Square Icon={SiPrisma} skillName='Prisma'/>
        <Square Icon={SiMysql} skillName='MySQL'/>
        <Square Icon={SiPostgresql} skillName='PostgreSQL'/>
        <Square Icon={SiFirebase} skillName='Firebase'/>
        <Square Icon={FaNodeJs} skillName='Node.js'/>
      </div>
        
    </div>
  )
}

export default Squares