import { useEffect, useState } from 'react'


export const useMobile = (window, mobileWidth = 1000) => {

        const [width, setWidth] = useState(window.innerWidth);

    
    
    useEffect(() => {
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [window]);

    return (width <= mobileWidth);
}

