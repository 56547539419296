import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { motion } from 'framer-motion'
import { MdOutlineExpandMore } from 'react-icons/md'

import Project from '../Project/Project'
import './Projects.scss'

const API_URL = 'https://api.github.com/users/usinakenes'

const Projects = ({ projectsRef }) => {

  const [repos, setRepos] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [reposShown, setReposShown] = useState(4)

  useEffect(() => {
    axios.get(API_URL + '/repos')
      .then(res => {
        setRepos(res.data)
      })
      .catch(err => {
        console.log(err);
      })

      setIsLoading(false)

  }, [])


  console.log(repos);

  return (
    <div ref={projectsRef} className='projects'>
        <motion.h1 
          className='projects-title' 
          whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.4 }}
        >PUBLIC REPOSITORIES</motion.h1>
        {isLoading ? (
          <p>Loading</p>
        ) : (
          <div>
            {repos
              .sort((a, b) => (new Date(b.updated_at) - new Date(a.updated_at)))
              .slice(0, reposShown)
              .map((repo) => (
              <Project 
                key={repo.id} 
                name={repo.name} 
                description={repo.description}
                date={moment(repo.updated_at).format('DD-MM-YYYY')}
                stars={repo.stargazers_count}
                watchers={repo.watchers_count}
                forks={repo.forks_count}
                url={repo.html_url}
              />
            ))}
          </div>
        )}
        {reposShown < repos.length && 
          <motion.div
            whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
            transition={{ duration: 0.4 }}
          >
            <MdOutlineExpandMore className='more-button' onClick={() => {setReposShown( prev => prev + 4)}}/>
          </motion.div>
        }
            
    </div>
  )
}

export default Projects