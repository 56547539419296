import React, { useContext, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { FiMoon } from 'react-icons/fi'
import { GrLinkedinOption } from 'react-icons/gr'
import { BsGithub } from 'react-icons/bs'
import { AiFillFilePdf } from 'react-icons/ai'
import { FiExternalLink } from 'react-icons/fi'

import { useMobile } from '../../hooks/useMobile'

import Switch from '../Switch/Switch'
import './About.scss'
import { ThemeContext } from '../../context/ThemeContext'

const About = ({ refs, isGraphic, setIsGraphic }) => {

  const { theme } = useContext(ThemeContext)

  const [skillsRef, squaresRef, projectsRef, contactRef, featuredRef] = refs

  const [skillsHovered, setSkillsHovered] = useState(false)
  const [technologiessHovered, setTechnologiessHovered] = useState(false)
  const [projectsHovered, setProjectsHovered] = useState(false)
  const [featuredHovered, setFeaturedHovered] = useState(false)


  const isMobile = useMobile(window)

  return (
    <div className='about'>
        <motion.div className='title-area'>
            <motion.div 
                whileInView={isMobile ? { y: [-50, 0], opacity: [0, 0, 1] } : { x: [-200, 0], opacity: [0, 1] }}
                transition={isMobile ? { duration: 1, delay: 0 } : { duration: 0.7, delay: 0 }}
                className='title'
                
            >
                Hello, I'm<br/>Sina Kenes.
            </motion.div>
            <motion.div
                className='tags'
            >
                <motion.div 
                    whileInView={{ y: [-20, -20, -20, 0], opacity: [0, 0, 0, 1] }}
                    transition={isMobile ? { duration: 1.5, delay: 0.7 } : { duration: 1.5, delay: 0.7 }}
                    className='tag'
                >
                    <p>Web Developer</p>
                </motion.div>
                <motion.div 
                    whileInView={{ y: [-20, -20, -20, 0], opacity: [0, 0, 0, 1] }}
                    transition={isMobile ? { duration: 1.5, delay: 0.8 } : { duration: 1.5, delay: 0.8 }}
                    className='tag'
                >
                    <p>UI/UX Designer</p>
                </motion.div>
                <motion.div 
                    whileInView={{ y: [-20, -20, -20, 0], opacity: [0, 0, 0, 1] }}
                    transition={isMobile ? { duration: 1.5, delay: 0.9 } : { duration: 1.5, delay: 0.9 }}
                    className='tag creative-tag'
                    onClick={(e) => setIsGraphic(true)}
                >
                    <p>Graphic Designer</p>
                </motion.div>
            </motion.div>
            <motion.div 
                whileInView={isMobile ? { y: [-50, 0], opacity: [0, 0, 1] } : { x: [-240, 0], opacity: [0, 1] }}
                transition={isMobile ? { duration: 1, delay: 0.2 } : { duration: 0.9, delay: 0.4 }}
                className='description'
            >
                I'm a full-stack web developer based in Stockholm, Sweden. I specialize in creating custom web applications that aim to exceed client expectations. I also do <span className='graphic-link' onClick={(e) => setIsGraphic(true)}>graphic design <FiExternalLink style={{opacity: '90%', transform: 'translateY(1px)'}} /></span>. Currently working at <a href='https://kollin.io' rel='noreferrer' target='_blank' className='graphic-link'>Kollin <FiExternalLink style={{opacity: '90%', transform: 'translateY(1px)'}} /></a>.
            </motion.div>
        </motion.div>
        <motion.div
            whileInView={isMobile ? { y: [-50, 0], opacity: [0, 0, 1] } : { x: [-280, 0], opacity: [0, 1] }}
            transition={isMobile ? { duration: 1, delay: 0.4 } : { duration: 1.1, delay: 0.4 }}
            className='sections-area'
        >
            <ul className='sections'>
                <li 
                    className='section'
                    onMouseEnter={(e) => {setSkillsHovered(true)}}
                    onMouseLeave={(e) => {setSkillsHovered(false)}}
                    onClick={(e) => {skillsRef.current?.scrollIntoView({behavior: 'smooth'})}}
                >
                    <p className='order-no'>01.</p>
                    <motion.div 
                        animate={skillsHovered ? { width: 170 } : { width: 75 }}
                        className='line line-1'
                    >
                    </motion.div>
                    <p className='section-title'>Skills</p>
                </li>
                <li 
                    className='section'
                    onMouseEnter={(e) => {setTechnologiessHovered(true)}}
                    onMouseLeave={(e) => {setTechnologiessHovered(false)}}
                    onClick={(e) => {squaresRef.current?.scrollIntoView({behavior: 'smooth'})}}
                >
                    <p className='order-no'>02.</p>
                    <motion.div 
                        className='line line-2'
                        animate={technologiessHovered ? { width: 180 } : { width: 85 }}
                    >
                    </motion.div>
                    <p className='section-title'>Technologies</p>
                </li>
                <li 
                    className='section'
                    onMouseEnter={(e) => {setFeaturedHovered(true)}}
                    onMouseLeave={(e) => {setFeaturedHovered(false)}}
             
                    onClick={(e) => {featuredRef.current?.scrollIntoView({behavior: 'smooth'})}}
                >
                    <p className='order-no'>03.</p>
                    <motion.div 
                        className='line line-3'
                        animate={featuredHovered ? { width: 160 } : { width: 65 }}
                        
                    >
                    </motion.div>
                    <p className='section-title'>Featured Projects</p>
                </li>
                <li 
                    className='section'
                    onMouseEnter={(e) => {setProjectsHovered(true)}}
                    onMouseLeave={(e) => {setProjectsHovered(false)}}
                    onClick={(e) => {projectsRef.current?.scrollIntoView({behavior: 'smooth'})}}
                >
                    <p className='order-no'>04.</p>
                    <motion.div 
                        className='line line-3'
                        animate={projectsHovered ? { width: 140 } : { width: 45 }}
                        
                    >
                    </motion.div>
                    <p className='section-title'>Public Repositories</p>
                </li>
                {/* <li 
                    className='section'
                    onMouseEnter={(e) => {setContactHovered(true)}}
                    onMouseLeave={(e) => {setContactHovered(false)}}
                    onClick={(e) => {contactRef.current?.scrollIntoView({behavior: 'smooth'})}}
                >
                    <p className='order-no'>05.</p>
                    <motion.div 
                        className='line line-4'
                        animate={contactHovered ? { width: 130 } : { width: 35 }}
                        
                    >
                    </motion.div>
                    <p className='section-title'>Contact</p>
                </li> */}
                
            </ul>
        </motion.div>
        <motion.div 
            whileInView={{ y: [50, 0], opacity: [0, 1] }}
            transition={{ duration: 0.7 }}
            className='footer'
        >
            {/* <div className='switch-area'>
                <FiMoon className='moon' />
                <Switch isLightMode={isLightMode} setIsLightMode={setIsLightMode}/>
            </div> */}
            <div className='social-media'>
                <a href="https://www.linkedin.com/in/umut-sina-kenes-17892b164/" target="_blank" rel="noreferrer" className='social-item linkedin'>
                    <GrLinkedinOption className='linkedin-icon'/>
                    <p>Linkedin</p>
                </a>
                <a href="https://github.com/usinakenes" target="_blank" rel="noreferrer" className='social-item github'>
                    <BsGithub className='github-icon' />
                    <p>Github</p>
                </a>
                <a href="/CV_Sina_Kenes_2024.pdf" target="_blank" rel="noreferrer" className='social-item cv'>
                    <AiFillFilePdf className='pdf-icon' />
                    <p>CV</p>
                </a>
            </div>
        </motion.div>
        
    </div>
  )
}

export default About